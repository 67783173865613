import * as React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/prop-types */
import cl from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import styled from 'styled-components/macro';
import { ResponsiveLayoutWithCss } from 'shared/Layout/ResponsiveLayoutWithCss';
import { withHomePageSection } from 'components/withHomePageSection';
import { Panel, PanelBody, PanelHeader } from 'components/panel';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import ButtonText from 'components/ButtonText';
import { TitleIcon } from 'components/TitleIcon';

import { Images, InfoProps } from './Certificates';
import Modal from './Modal';
import { fromScreen } from 'utils/media-query/responsive.util';

const infoType = {
  POPUP: 'popup',
  LINK: 'link',
  VIDEO: 'video'
};

const CardAnimation = styled.div`
  &.reverse {
    order: 2;
    transform: rotateY(180deg);
    div > img {
      transform: rotateY(180deg);
    }
  }
  position: relative;
  > div {
    position: absolute;
  }
  .item0 {
    width: 312px;
    transition: all 0.2s;
    transform: translate(200px, 20px);
  }
  .item1 {
    width: 176px;
    left: 0;
    transition: all 0.2s;
    opacity: 0.6;
  }
  .item2 {
    width: 176px;
    left: 0;
    top: 150px;
    transition: all 0.2s;
    opacity: 0.6;
  }
  .center {
    width: 312px !important;
    transform: translate(200px, -120px);
    opacity: 1 !important;
  }
  .left-bottom {
    width: 176px;
    left: 0;
    top: 150px;
    opacity: 0.6;
  }
  .left-top {
    width: 176px;
    left: 0;
    transform: translate(0, 0);
    opacity: 0.6;
  }
`;

const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  .desktop-title {
    display: none;
  }
  ${fromScreen(776)} {
    gap: 40px;
  }
  ${fromScreen(1144)} {
    .title {
      margin: 0 auto;
    }
    .mobile-title {
      display: none;
    }
    .desktop-title {
      display: block;
    }
  }
`;

const DifferencesTablet = ({ differences }) => {
  return (
    <>
      <DifferenceListTablet>
        {differences
          .sort((diff1, diff2) => diff1.order - diff2.order)
          .map(diff => (
            <DifferenceTablet key={diff.imageHandle} diff={diff} />
          ))}
      </DifferenceListTablet>
    </>
  );
};

const DifferencesListContainer = ({ data, isReverse = false }) => {
  const [selected, setSelected] = React.useState(data[0].order);
  const [firstLoaded, setFirstLoaded] = React.useState(true);

  React.useEffect(() => {
    if (firstLoaded) setFirstLoaded(false);
    if (!firstLoaded) {
      const type = isReverse ? 1 : 0;
      const selectedItem = document.getElementsByClassName('selected')[type];
      const headerEl1 = document.getElementsByClassName('item1')[type];
      const defaultSelectedItem =
        document.getElementsByClassName('item0')[type];

      if (selectedItem !== defaultSelectedItem) {
        selectedItem.classList.add('center');
        headerEl1.classList.add('left-bottom');
        defaultSelectedItem.classList.add('left-top');
      } else {
        headerEl1.classList.remove('left-bottom');
      }
    }
  }, [selected, isReverse, firstLoaded]);

  return (
    <DifferenceList>
      <CardAnimation className={cl({ reverse: isReverse })}>
        {data
          .sort((diff1, diff2) => diff1.order - diff2.order)
          .map((diff, key) => {
            if (diff.imageHandle)
              return (
                <LazyImage
                  imgProps={{
                    alt: diff.title
                  }}
                  handle={diff.imageHandle}
                  key={key}
                  className={cl(`item${key}`, {
                    selected: selected === diff.order
                  })}
                />
              );
          })}
      </CardAnimation>
      <div>
        {data
          .sort((diff1, diff2) => diff1.order - diff2.order)
          .map(diff => (
            <Difference
              selected={selected}
              key={diff.order}
              diff={diff}
              setSelected={() => setSelected(diff.order)}
            />
          ))}
      </div>
    </DifferenceList>
  );
};

const Differences = ({ differences }) => {
  return (
    <>
      <div className="content">
        <DifferencesListContainer data={differences.slice(0, 3)} />
        <DifferencesListContainer data={differences.slice(3, 6)} isReverse />
      </div>
    </>
  );
};

Differences.propTypes = {
  differences: PropTypes.shape({
    heading: PropTypes.shape({
      emphasize: PropTypes.string,
      text: PropTypes.string
    }),
    differences: PropTypes.arrayOf(DiffType)
  })
};

export const useLandingDifferences = () =>
  useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "LandingCourse_DifferencesV2" }) {
          content
        }
      }
    }
  `);

export const GCMSDifferencesHome = withHomePageSection(
  ({ title, showIcon = true }) => {
    const data = useLandingDifferences();
    return (
      <DifferencesCommon
        data={data.gcms.jsonContanier.content.differences}
        title={title}
        showIcon={showIcon}
      />
    );
  }
);

export const DifferencesCommon = ({ data, title, mobileTitle, showIcon }) => {
  return (
    <Wrapper>
      <TitleIcon
        iconHandle={showIcon && 'RY1uavGWQqW2aZ0GGJDo'}
        className="title"
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
          className={cl({ 'desktop-title': mobileTitle })}
        >
          {title ? title : '6 điểm khác biệt của DOL'}
        </Typography>
        {mobileTitle && (
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            v3
            className="mobile-title"
            as="h2"
          >
            {mobileTitle}
          </Typography>
        )}
      </TitleIcon>
      <ResponsiveLayoutWithCss
        breakpointWithComponents={[
          {
            toBreakpoint: 1143,
            renderComponent: <DifferencesTablet differences={data} />
          },
          {
            fromBreakpoint: 1144,
            renderComponent: <Differences differences={data} />
          }
        ]}
      />
    </Wrapper>
  );
};

const DifferenceList = styled.div`
  display: grid;
  gap: 56px;
  grid-template-columns: 1fr 1fr;
  &:last-child {
    margin-top: 100px;
  }
  .button-text {
    margin-top: 18px;
  }
`;

const DifferenceListTablet = styled.div`
  display: grid;
  gap: 24px;
  .button-text {
    margin-top: 10px;
  }
  .text-container {
    margin-top: 8px;
  }
  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }
`;

const normalizeDifferencesHTML = htmlContent => {
  return (
    htmlContent
      // replace p with span
      .replace(/<p[^>]*>/g, '<span>')
      .replace(/<\/p>/g, '</span>')
      // remove div
      .replace(/<div[^>]*>/g, '')
      .replace(/<\/div>/g, '')
  );
};

const Session = styled(Panel)`
  border: none;

  &.expanded {
    background-color: ${colorsV2.white100};
  }
  .panel-body {
    padding: 12px 0 0 0 !important;
  }
`;

const SessionHeader = styled(PanelHeader)`
  padding: 0 0;
  &.expanded {
    border: none;
    position: relative;
  }
`;

const BodyWrapper = styled.div`
  max-width: 480px;
`;

const Body = ({ diff }) => {
  const { htmlContent } = diff;
  const [showModal, setShowModal] = React.useState(false);
  const hasInfo = !!diff.info;
  const hasPopup = hasInfo && diff.info.type === infoType.POPUP;
  const hasLink = hasInfo && diff.info.type === infoType.LINK;
  const hasVideo = hasInfo && diff.info.type === infoType.VIDEO;
  const isYoutubeVideo = get(diff, 'info.content.link.url', '').includes(
    'youtu'
  );
  return (
    <BodyWrapper>
      <Typography
        type="span"
        variant="regular/14"
        style={{
          color: colorsV2.black80
        }}
        dangerouslySetInnerHTML={{
          __html: normalizeDifferencesHTML(htmlContent)
        }}
      />

      {hasPopup ? (
        <div>
          <ButtonText
            content={diff.info.content.link.title}
            type="primary"
            onClick={() => setShowModal(true)}
          />
          <Modal
            title={
              diff.info.content.link.modalTitle || diff.info.content.link.title
            }
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
          >
            <Images images={diff.info.content.images} />
          </Modal>
        </div>
      ) : null}

      {hasLink ? (
        <div className="link">
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href={diff.info.content.link.url}
          >
            <ButtonText content={diff.info.content.link.title} type="primary" />
          </Link>
        </div>
      ) : null}
      {hasVideo && (
        <VideoLink className="video-link">
          <ButtonText
            content={diff.info.content.link.title}
            type="primary"
            onClick={() => setShowModal(true)}
          />
          <Modal
            title={
              isYoutubeVideo
                ? null
                : diff.info.content.link.modalTitle ||
                  diff.info.content.link.title
            }
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
          >
            {isYoutubeVideo ? (
              <div className="iframe-container">
                <iframe
                  id="video-auto-play-modal"
                  src={diff.info.content.link.url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <video
                className="videoPlayer"
                controls
                preload="meta"
                autoPlay
                id="video-auto-play-modal"
                src={diff.info.content.link.url}
                type="video/mp4"
              >
                <track kind="captions" />
              </video>
            )}
          </Modal>
        </VideoLink>
      )}
    </BodyWrapper>
  );
};

export const Difference = ({ diff, setSelected, selected }) => {
  const { title } = diff;

  const hasInfo = !!diff.info;
  const hasPopup = hasInfo && diff.info.type === infoType.POPUP;

  return (
    <CardDifferenceDesktop>
      <DifferenceContainer>
        <Session
          key={diff.order}
          expanded={selected === diff.order}
          noToggle
          onClick={setSelected}
        >
          <SessionHeader>
            <div className="text-container">
              <Title className={cl({ 'has-info': hasPopup })}>
                <Typography
                  variant="semi-bold/16"
                  tabletVariant="semi-bold/20-28"
                  v3
                  as="h3"
                >
                  {diff.order}. {title}
                </Typography>
              </Title>
            </div>
          </SessionHeader>
          <PanelBody>
            <Body diff={diff} />
          </PanelBody>
        </Session>
      </DifferenceContainer>
    </CardDifferenceDesktop>
  );
};

const DifferenceTablet = ({ diff }) => {
  const { imageHandle, title } = diff;

  const hasInfo = !!diff.info;
  const hasPopup = hasInfo && diff.info.type === infoType.POPUP;

  return (
    <>
      <DifferenceContainer>
        <div className="image-container">
          <LazyImage
            imgProps={{
              alt: 'differences'
            }}
            handle={imageHandle}
          />
        </div>
        <div className="text-container">
          <Title className={cl({ 'has-info': hasPopup })}>
            <Typography variant="semi-bold/20-28" as="h3">
              {diff.order}. {title}
            </Typography>
          </Title>
        </div>

        <Body diff={diff} />
      </DifferenceContainer>
    </>
  );
};

const Title = styled.div`
  display: inline-grid;
  align-items: baseline;
`;

const DiffType = PropTypes.shape({
  svgHandle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  htmlContent: PropTypes.string.isRequired,
  order: PropTypes.number,
  info: InfoProps
});

Difference.propTypes = {
  diff: DiffType
};

const VideoLink = styled.div`
  margin-top: 12px;
  color: ${colorsV2.primary100};
  cursor: pointer;
`;

const DifferenceContainer = styled.div``;
const CardDifferenceDesktop = styled.div`
  padding-bottom: 28px;
  :not(:first-child) {
    padding-top: 28px;
  }
  :not(:last-child) {
    border-bottom: 1px solid ${colorsV2.gray20};
  }
`;
