import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';
import cl from 'classnames';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import ButtonText from 'components/ButtonText';

export const HeadlineIcon = React.memo(props => {
  const {
    children,
    iconHandle,
    center,
    url,
    className,
    viewDetail,
    isHomePage
  } = props;

  return (
    <Wrapper className={cl(className, { 'hide-mobile': isHomePage })}>
      <Main className={cl({ center })}>
        {children}
        {iconHandle && (
          <LazyImage
            handle={iconHandle}
            className="headline-icon"
            alt="arrow-icon"
          />
        )}
      </Main>
      {url && (
        <Link to={url}>
          <ButtonText
            content={viewDetail ? 'Xem chi tiết' : 'Xem tất cả'}
            type="primary"
          />
        </Link>
      )}
    </Wrapper>
  );
});

const Main = styled.div`
  position: relative;
  .headline-icon {
    display: none;
    ${fromScreen(776)} {
      display: block;
      position: absolute;
      top: -10px;
      right: -25px;
    }
  }
  width: fit-content;
  &.center {
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  &.hide-mobile {
    > a {
      display: none;
      ${fromScreen(776)} {
        display: block;
      }
    }
  }
`;

HeadlineIcon.propTypes = {
  iconHandle: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  viewDetail: PropTypes.bool,
  isHomePage: PropTypes.bool
};
