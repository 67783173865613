/* eslint-disable react/prop-types */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { uniqBy } from 'lodash';
import { TeachersTabMobile } from './TeacherTabMobile';
import { VideoPlayer } from '../Linearthinking/YoutubeVideoCourses';
import Modal from '../CourseLanding/Modal';
import { ResponsiveLayout } from '../Layout/ResponsiveLayout';
import { TeacherTab } from './TeacherTab';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';

export const getUniqueTeachers = data => uniqBy(data, 'name');

export const useAllTeacherData = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDolLandingPageMarLevelData {
        nodes {
          levelVideo
          levelVideoThumbnailHandle
          availableSeat
          classCode
          classDate
          classGroup
          classListing
          classTime
          inputLevel
          level
          slug
          levelDescription
          levelDisplay
          outputLevel
          startDate
          teacherRw
          teacherSl
          levelSubtext
          levelInputOutputMobile
          levelInputOutputDesktop
          levelBestseller
          levelUpdated
          numOfStudent
          whatYouGet1
          whatYouGet2
          whatYouGet3
          whatYouGet4
          whatYouGet5
          whatYouGet6
          whatYouGet7
          whatYouGet8
        }
      }
      allGoogleSpreadsheetDolLandingPageTeacherInfo {
        nodes {
          id
          name
          url
          displayName
          fullName
          feedbackScore
          listingImageHandler
          feedbackReceive
          highlightTitle4
          highlightTitle3
          highlightTitle2
          highlightTitle1
          highlightSubtext4
          highlightSubtext3
          highlightSubtext2
          highlightSubtext1
          highlightIcon4Handler
          highlightIcon3Handler
          highlightIcon2Handler
          highlightIcon1Handler
          displayNameSubtitle
          detailImageHandler
          teacherDescription
          numberOfStudent
          videoIntroduction
        }
      }
    }
  `);
  const teachers = data.allGoogleSpreadsheetDolLandingPageTeacherInfo.nodes
    .map(item => {
      const {
        id,
        highlightTitle4,
        highlightTitle3,
        highlightTitle2,
        highlightTitle1,
        highlightSubtext4,
        highlightSubtext3,
        highlightSubtext2,
        highlightSubtext1,
        highlightIcon4Handler,
        highlightIcon3Handler,
        highlightIcon2Handler,
        highlightIcon1Handler
      } = item;
      item.highlights = [
        {
          iconHandler: 'ww4nKqwCRp6UIHCk2DOe',
          id: 'linear-badge',
          title: 'Linearthinking Ambassador'
        },
        {
          id: `${id}-1`,
          title: highlightTitle1,
          subText: highlightSubtext1,
          iconHandler: highlightIcon1Handler
        },
        {
          id: `${id}-2`,
          title: highlightTitle2,
          subText: highlightSubtext2,
          iconHandler: highlightIcon2Handler
        },
        {
          id: `${id}-3`,
          title: highlightTitle3,
          subText: highlightSubtext3,
          iconHandler: highlightIcon3Handler
        },
        {
          id: `${id}-4`,
          title: highlightTitle4,
          subText: highlightSubtext4,
          iconHandler: highlightIcon4Handler
        }
      ].filter(item => Boolean(item.title));
      return item;
    })
    .filter(item => Boolean(item?.url));

  const allLevels = data.allGoogleSpreadsheetDolLandingPageMarLevelData.nodes;

  const getTeacherOfLevel = levelName => {
    const levelFound = allLevels.find(item => item.level === levelName);
    const levelTeachers = teachers.filter(
      teacher =>
        teacher.name?.includes(levelFound.teacherRw) ||
        teacher.name?.includes(levelFound.teacherSl)
    );
    return levelTeachers;
  };

  return { getTeacherOfLevel, teachers, allLevels };
};

const AllDolTeachersV2 = () => {
  const { teachers } = useAllTeacherData();
  const [isInitialized] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState(0);

  // React.useEffect(() => {
  //   if (!isInitialized && teachers && teachers.length) {
  //     const originalHash = get(window, 'location.hash', '') || '';
  //     const hashName = originalHash.toLowerCase();
  //     const teacherIndex = teachers.findIndex(teacher => {
  //       const teacherHashName = get(teacher, 'url', '').toLowerCase();
  //       return teacherHashName === hashName;
  //     });
  //     if (teacherIndex > -1) {
  //       setActiveIndex(teacherIndex);
  //     }
  //     setInitialized(true);
  //   }
  // }, [teachers, isInitialized, window]);

  return (
    <>
      <ResponsiveLayout
        breakpointWithComponents={[
          {
            breakpoint: 775,
            renderComponent: (
              <TeachersTabMobile
                data={teachers}
                setVideoUrl={setVideoUrl}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setShowModal={setShowModal}
                viewMoreUrl="/linearteachers"
              />
            )
          },
          {
            breakpoint: 776,
            ssrDefault: true,
            renderComponent: (
              <TeacherTab
                isInitialized={isInitialized}
                data={teachers}
                setVideoUrl={setVideoUrl}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setShowModal={setShowModal}
                viewMoreUrl="/linearteachers"
              />
            )
          }
        ]}
      />

      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </>
  );
};

export default withHomePageSection(
  AllDolTeachersV2,
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
