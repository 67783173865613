import * as React from 'react';
import {
  betweenWidth,
  fromWidth,
  toWidth
} from '../../utils/media-query/media-query';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: none;
  ${props =>
    props.displayOnQuery &&
    ` @media ${props.displayOnQuery} {
        display:initial;
    }`}
`;

function toQueryListPromotion(breakpointWithComponents) {
  return breakpointWithComponents
    .filter(elm => elm.toBreakpoint || elm.fromBreakpoint)
    .map(({ toBreakpoint, fromBreakpoint, renderComponent, ssrDefault }) => {
      let query;
      if (toBreakpoint && !fromBreakpoint) {
        query = toWidth(toBreakpoint);
      } else if (!toBreakpoint && fromBreakpoint) {
        query = fromWidth(fromBreakpoint);
      } else {
        query = betweenWidth(fromBreakpoint, toBreakpoint);
      }
      return { query, renderComponent, ssrDefault };
    });
}

const ResponsiveLayout = ({ breakpointWithComponents }) => {
  // TODO: change to use callback & update foundBreakpoint when window resize, to reduce number of re-rendering
  const queryList = React.useMemo(
    () => toQueryListPromotion(breakpointWithComponents),
    [breakpointWithComponents]
  );
  // render all components in queryList
  // Each component is wrapped in styled component (recieving displayOnQuery prop)
  // displayOnQuery prop will be used for @media ${displayOnQuery} {display: initial}

  if (queryList.length === 0) return null;

  return (
    <>
      {queryList.map(comp => (
        <Wrapper key={comp} displayOnQuery={comp.query}>
          {comp.renderComponent}
        </Wrapper>
      ))}
    </>
  );
};

export const ResponsiveLayoutWithCss = ResponsiveLayout;
