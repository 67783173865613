import * as React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import cl from 'classnames';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

export const TitleIcon = React.memo(props => {
  const { children, iconHandle, center, className } = props;

  return (
    <Main className={cl(className, { center })}>
      {children}
      {iconHandle && (
        <LazyImage
          handle={iconHandle}
          className="headline-icon"
          alt="headline-icon"
        />
      )}
    </Main>
  );
});

const Main = styled.div`
  position: relative;
  .headline-icon {
    display: none;
    ${fromScreen(776)} {
      display: block;
      position: absolute;
      top: -10px;
      right: -25px;
    }
  }
  width: fit-content;
  &.center {
    margin: 0 auto;
  }
`;

TitleIcon.propTypes = {
  iconHandle: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.node.isRequired
};
