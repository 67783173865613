import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { get } from 'lodash';
import { LazyImage } from '../../components/LazyImage';
import { HeadlineIcon } from 'components/HeadlineIcon';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';

const CardInfo = styled.div``;
const TeacherCard = styled.div`
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${colorsV2.gray40};

  ${fromScreen(776)} {
    padding: 12px 0px;
  }
  ${fromScreen(1144)} {
    padding: 16px 0;
    row-gap: 12px;
  }

  display: grid;
  place-items: center;
  row-gap: 4px;
  text-align: center;
  img {
    width: 64px;
    height: 64px;
    opacity: 0.7;
  }
  transition: all 0.3s;

  :hover {
    border: 1px solid ${colorsV2.primary100};
    .teacher-name {
      color: ${colorsV2.primary100};
    }
    img {
      opacity: 1;
    }
  }

  &.active {
    background-color: ${colorsV2.primary10};
    border: 1px solid ${colorsV2.primary100};
    .teacher-name {
      color: ${colorsV2.primary100};
    }
    img {
      opacity: 1;
    }
  }
`;
const TeacherCarouselWrapper = styled.div``;

const TeacherCarousel = ({
  isInitialized,
  activeIndex,
  data,
  setActiveIndex
}) => {
  const selectedTeacher = data[activeIndex];

  React.useEffect(() => {
    if (isInitialized) {
      window.history.replaceState(undefined, undefined, selectedTeacher.url);
    }
  }, [isInitialized, selectedTeacher.url]);

  return (
    <TeacherCarouselWrapper>
      {data.slice(0, 5).map((item, index) => (
        <TeacherCard
          className={cl({
            active: activeIndex === index
          })}
          onClick={() => {
            setActiveIndex(index);
          }}
          id={get(item, 'url', '').replace(/#/gi, '').toLowerCase()}
          key={item.id}
        >
          <LazyImage
            handle={item.detailImageHandler}
            alt={`${item.name} avatar`}
          />
          <CardInfo>
            <Typography
              desktopVariant="medium/20-28"
              variant="medium/16-24"
              className="teacher-name"
              v3
              color={colorsV2.black80}
            >
              {item.fullName}
            </Typography>
          </CardInfo>
        </TeacherCard>
      ))}
    </TeacherCarouselWrapper>
  );
};

const TeacherAvatar = styled.div`
  img {
    width: 100%;
    border-radius: 8px;
  }
  position: relative;
  height: max-content;
  .linear-badge {
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12px;
    background: ${colorsV2.white100};
    padding: 4px 8px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .icon-linear {
      width: 16px;
      margin-right: 2px;
    }
  }
`;
const HighlightItem = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 12px;
  align-items: center;
  min-height: 44px;
`;
const HighlightItemList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  grid-auto-rows: min-content;
  ${fromScreen(776)} {
    grid-row-gap: 8px;
    margin-top: 20px;
    ${HighlightItem} {
      grid-template-columns: 28px auto;
    }
  }
  ${fromScreen(1144)} {
    grid-row-gap: 20px;
    grid-column-gap: 16px;
    margin-top: 36px;
    ${HighlightItem} {
      grid-template-columns: 32px auto;
    }
  }
`;
const TeacherHighlight = styled.div`
  display: grid;

  ${fromScreen(776)} {
    grid-template-columns: 276px auto;
    gap: 12px;
  }

  ${fromScreen(1144)} {
    grid-template-columns: 350px auto;
    gap: 24px;
  }
`;
const TeacherNameSection = styled.div``;
const TeacherInfoWrapper = styled.div`
  margin-top: 32px;
`;

const TeacherInfo = ({ activeIndex, data }) => {
  const selectedTeacher = data[activeIndex];
  return (
    <TeacherInfoWrapper>
      <TeacherHighlight>
        <TeacherAvatar>
          <div>
            <LazyImage
              handle={selectedTeacher.listingImageHandler}
              alt={`${selectedTeacher.name} profile`}
            />
          </div>
          <div className="linear-badge">
            <LazyImage
              handle="ww4nKqwCRp6UIHCk2DOe"
              className="icon-linear"
              alt="icon-linearthinking"
            />
            <Typography variant="medium/12-20" color={colorsV2.primary100}>
              Linearthinking Ambassador
            </Typography>
          </div>
        </TeacherAvatar>

        <div>
          <TeacherNameSection>
            <Typography
              desktopVariant="semi-bold/24-32"
              variant="semi-bold/20-28"
              v3
            >
              {get(selectedTeacher, 'fullName', '...')}
            </Typography>
            <Typography
              style={{ color: colorsV2.black80 }}
              variant="regular/14"
              desktopVariant="regular/16"
            >
              {get(selectedTeacher, 'displayNameSubtitle', '...')}
            </Typography>
          </TeacherNameSection>
          <HighlightItemList>
            {get(selectedTeacher, 'highlights', []).map(highlight => {
              const { id, title, subText, iconHandler } = highlight;
              if (id !== 'linear-badge')
                return (
                  <HighlightItem key={id}>
                    <div className="icon">
                      <LazyImage handle={iconHandler} alt="icon-highlight" />
                    </div>
                    <div className="highlight-content">
                      <div className="title">
                        <Typography
                          variant="semi-bold/16-24"
                          desktopvariant="regular/20-24"
                          v3
                        >
                          {title}
                        </Typography>
                      </div>
                      <div className="sub-text">
                        <Typography
                          style={{ color: colorsV2.black80 }}
                          desktopvariant="regular/16-24"
                          variant="regular/14-20"
                          v3
                        >
                          {subText}
                        </Typography>
                      </div>
                    </div>
                  </HighlightItem>
                );
            })}
          </HighlightItemList>
        </div>
      </TeacherHighlight>
    </TeacherInfoWrapper>
  );
};
const ContentSection = styled.div`
  display: grid;
  grid-auto-flow: row;

  ${TeacherCarouselWrapper} {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }

  ${fromScreen(776)} {
    ${TeacherCarouselWrapper} {
      gap: 16px;
    }
  }
  ${fromScreen(1144)} {
    ${TeacherCarouselWrapper} {
      gap: 24px;
    }
    .section-title {
      text-align: center;
    }
  }
`;

const TeachersHorizontalCarouselWrapper = styled.div`
  background: ${props => (props.hasBgColor ? colorsV2.paper : '')};
  width: 100%;
`;
export const TeacherTab = props => {
  const { hasBgColor = true, viewMoreUrl, sectionTitle } = props;
  return (
    <TeachersHorizontalCarouselWrapper hasBgColor={hasBgColor}>
      <ContentSection>
        {sectionTitle ? (
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            v3
            className="section-title"
            as="h2"
          >
            {sectionTitle}
          </Typography>
        ) : (
          <HeadlineIcon
            iconHandle="0GYERmATmSRJWjiRPE4w"
            url={viewMoreUrl}
            isHomePage
          >
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              v3
              as="h2"
            >
              Đội ngũ giáo viên
            </Typography>
          </HeadlineIcon>
        )}

        <TeacherCarousel {...props} />
        <TeacherInfo {...props} />
      </ContentSection>
    </TeachersHorizontalCarouselWrapper>
  );
};
